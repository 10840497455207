import {
  InquiriesTypes,
  ProjectTypes,
  ProductionTypes,
  CommentTypes
} from '../actions'
import _ from 'lodash'
const initialState = {
  data: [],
  current: null,
  clientResults: [],
  searchedClients: [],
  actSuppliers: [],
  categories: [],
  offersData: {},
  searchedOffersData: [],
  salesData: [],
  searchedProductionData: [],
  createdActOfAcceptance: false,
  activeThisMonth: 0,
  activeThisWeek: 0,
  zones: [],
  pageNumber: 1,
  hasNextPage: false,
  inquiries: [],
  inquiriesPage: 1,
  totalDocs: 0,
  statuses: [],
  inquiriesExpenseMachine: [],
  inquiriesExpenseEndProduct: [],
  totalExpense: 0,
  inquiriesDefectives: [],
  defectives: []
}

const editComment = (state, payload) => {
  if (state.current) {
    const newCurrent = { ...state.current }
    const requestIndex = newCurrent.productionRequests.findIndex(
      (request) => request._id === payload.ref._id
    )
    const commentIndex = newCurrent.productionRequests[
      requestIndex
    ].comments.findIndex((comment) => comment._id === payload._id)
    newCurrent.productionRequests[requestIndex].comments[commentIndex] = payload
    return newCurrent
  } else {
    return state.current
  }
}

const addInquiry = (state, payload) => {
  const inquiries = state.data.slice(0)
  inquiries.push(payload)
  return inquiries
}

const addInquiryNew = (state, payload) => {
  const inquiries = state.inquiries.slice(0)
  inquiries.unshift(payload)
  return inquiries
}

const addToData = (state, payload) => {
  const _data = state.data.slice(0)
  const index = _data.findIndex((el) => el._id === payload._id)
  if (index !== -1) {
    _data[index] = payload
  }
  return _data
}

const updateComment = (state, payload) => {
  const current = { ...state.current }
  current.comments = current.comments.map((c) => {
    if (c._id === payload._id) return payload
    return c
  })
  return current
}

const removeComment = (state, payload) => {
  const current = { ...state.current }
  current.comments = current.comments.filter((c) => {
    return c._id !== payload._id
  })
  return current
}

const removeInquiry = (state, payload) => {
  const arr = state.data.slice(0)
  const found = arr.findIndex((el) => {
    return el._id === payload.id
  })
  if (found !== -1) {
    arr.splice(found, 1)
  }

  return arr
}

const addProductionRequest = (state, payload) => {
  if (state.current) {
    const newCurrent = _.cloneDeep(state.current)
    newCurrent.productionRequests.push(payload)
    return newCurrent
  } else {
    return null
  }
}

const loadOffers = (state, payload) => {
  const newOffers = payload.offers.docs
  const offersObj = payload.offers

  if (offersObj.page !== 1) {
    return {
      ...payload.offers,
      docs: [...state.docs, ...newOffers]

    }
  }
  return payload.offers
}

const loadSalesResult = (state, action) => {
  state.salesData.filter(invoice => {
    return invoice.invoiceNumber === action.payload.invoiceNumber
  })

  return state.salesData
}

const inquiries = (state = initialState, action) => {
  switch (action.type) {
    case InquiriesTypes.GET_INQUIRIES_SUCCESS:
      return {
        ...state,
        data: action.payload.inquiries,
        activeThisMonth: action.payload.activeThisMonth,
        activeThisWeek: action.payload.activeThisWeek
      }
    case ProjectTypes.SEARCH_INQUIRIES_SUCCESS:
      return {
        ...state,
        activeThisMonth: action.payload.activeThisMonth,
        activeThisWeek: action.payload.activeThisWeek,
        totalDocs: action.payload.totalDocs,
        inquiriesPage: action.payload.nextPage,
        // inquiries: [...state.inquiries, ...action.payload.inquiries]
        inquiries: action.payload.page === 1 ? action.payload.inquiries : [...state.inquiries, ...action.payload.inquiries]
      }
    case InquiriesTypes.CREATE_INQUIRY_SUCCESS:
      return { ...state, data: addInquiry(state, action.payload), inquiries: addInquiryNew(state, action.payload) }
    case InquiriesTypes.GET_INQUIRY_SUCCESS:
      return { ...state, current: action.payload }
    case InquiriesTypes.UPDATE_INQUIRY_SUCCESS:
      return { ...state, current: action.payload }
    case InquiriesTypes.ADD_PRODUCTS_TO_INQUIRY_SUCCESS:
      return {
        ...state,
        current: action.payload,
        data: addToData(state, action.payload)
      }
    case InquiriesTypes.ADD_DOCUMENTS_TO_INQUIRY_SUCCESS:
      return {
        ...state,
        current: action.payload,
        data: addToData(state, action.payload)
      }
    case InquiriesTypes.CHANGE_WORKING_ON_SUCCESS:
      return {
        ...state,
        current: action.payload,
        data: addToData(state, action.payload)
      }
    case InquiriesTypes.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        current: action.payload,
        data: addToData(state, action.payload)
      }
    case InquiriesTypes.ADD_ACTIONS_SUCCESS:
      return {
        ...state,
        current: action.payload,
        data: addToData(state, action.payload)
      }
    case InquiriesTypes.UPDATE_COMMENT_SUCCESS:
      return { ...state, current: updateComment(state, action.payload) }
    case InquiriesTypes.REMOVE_COMMENT_SUCCESS:
      return { ...state, current: removeComment(state, action.payload) }
    case InquiriesTypes.DELETE_INQUIRY_SUCCESS:
      return { ...state, data: removeInquiry(state, action.payload) }
    case InquiriesTypes.CREATE_ZONE_SUCCESS:
      return { ...state, zones: [...state.zones, action.payload] }
    case InquiriesTypes.GET_ZONES_SUCCESS:
      return { ...state, zones: action.payload }
    case InquiriesTypes.SEARCH_INQUIRIES_PRODUCTION_SUCCESS:
      return { ...state, searchedProductionData: action.payload.data }
    case ProjectTypes.CREATE_PROJECT_OFFER_SUCCESS:
      return { ...state, current: action.payload.inquiry }
    case ProjectTypes.EDIT_PROJECT_OFFER_SUCCESS:
      return { ...state, current: action.payload.inquiry }
    case ProjectTypes.CREATE_PROJECT_INVOICE_SUCCESS:
      return { ...state, current: action.payload.inquiry }
    case ProjectTypes.EDIT_PROJET_INVOICE_SUCCESS:
      return { ...state, current: action.payload.inquiry }
    case ProjectTypes.SEARCH_OFFER_CLIENTS_SUCCESS:
      return { ...state, clientResults: action.payload.clients }
    case ProjectTypes.CREATE_ACT_OF_ACCEPTANCE_SUCCESS:
      return {
        ...state,
        current: action.payload.inquiry,
        createdActOfAcceptance: true
      }
    case ProjectTypes.RESET_ACT_OF_ACCEPTANCE_CREATION:
      return { ...state, createdActOfAcceptance: false }
    case ProjectTypes.EDIT_ACT_OF_ACCEPTANCE_SUCCESS:
      return { ...state, current: action.payload.inquiry }
    case ProjectTypes.RESET_OFFER_CLIENT_RESULTS:
      return { ...state, clientResults: [], actSuppliers: [] }
    case ProjectTypes.EDIT_INQUIRY_SUCCESS:
      return { ...state, current: action.payload.inquiry }
    case ProjectTypes.SEARCH_ACT_SUPPLIERS_SUCCESS:
      return { ...state, actSuppliers: action.payload.deliveryServices }
    case ProjectTypes.SEARCH_INQUIRY_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload }
    case ProductionTypes.POST_PRODUCTION_REQUEST_SUCCESS:
      return { ...state, current: addProductionRequest(state, action.payload) }
    case CommentTypes.EDIT_GENERAL_COMMENT_SUCCESS:
      return { ...state, current: editComment(state, action.payload) }
    case InquiriesTypes.GET_ALL_OFFERS_SUCCESS:
      return { ...state, offersData: loadOffers(state.offersData, action.payload) }
    case InquiriesTypes.SEARCH_INQUIRIES_OFFERS_SUCCESS:
      return { ...state, offersData: loadOffers(state.offersData, action.payload) }
    case InquiriesTypes.FILTER_INQUIRIES_OFFERS_SUCCESS:
      return { ...state, offersData: loadOffers(state.offersData, action.payload) }
    case InquiriesTypes.GET_ALL_SALES_SUCCESS:
      return { ...state, salesData: action.payload.products }
    case InquiriesTypes.SEARCH_SALES_SUCCESS:
      return { ...state, salesData: action.payload }
    case InquiriesTypes.CREATE_CHECKLIST_SUCCESS:
      return { ...state, current: {...state.current, checklist: [...state.current.checklist, action.payload?.inquiry]} }
    case InquiriesTypes.CREATE_INQUIRY_STATUS_SUCCESS:
      return { ...state, statuses: [...state.statuses, action.payload.status ] }
    case InquiriesTypes.GET_INQUIRY_STATUSES_SUCCESS:
      return { ...state, statuses: action.payload?.statuses || [] }
    case InquiriesTypes.GET_INQUIRY_EXPENSE_MACHINE_SUCCESS:
      return { ...state, inquiriesExpenseMachine: action.payload.entries, totalExpense: action.payload.totalMachineCostPrice || 0 }
    case InquiriesTypes.GET_INQUIRY_EXPENSE_END_PRODUCT_SUCCESS:
      return { ...state, inquiriesExpenseEndProduct: action.payload.entries, totalExpense: action.payload.totalMachineCostPrice || 0 }
    case InquiriesTypes.GET_INQUIRY_DEFECTIVES_SUCCESS:
      return { ...state, defectives: action.payload.page === 1 ? action.payload.docs : [...state.defectives, ...action.payload.docs], pageNumber: action.payload.page, hasNextPage: action.payload.hasNextPage }
    // case InquiriesTypes.SEARCH_CLIENTS_SUCCESS:
    //   return {...state, searchedClients: action.payload}
    // case InquiriesTypes.FILTER_CLIENTS_DATES_SUCCESS:
    //   return { ...state, searchedClients: action.payload }
    default:
      return state
  }
}

export default inquiries
