import React, { useState } from "react"
import { connect } from "react-redux"
import { Input, Button } from "@makedonski/admin-ui"
import moment from "moment"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"
import { hideModal, updateInquiry } from "../../../actions"
import "./styles.scss"
import { ClickAwayListener } from "@mui/material"
import { ClientAutocomplete } from "../../ClientAutocomplete"
import { isBudgetValid } from "../../../utilities/helpers"

function EditProductModal({ inquiry, update, hide }) {
  const [startDate, setStartDate] = useState(moment(inquiry.startDate).toDate())
  const [client, setClient] = useState(inquiry.client)
  const [budget, setBudget] = useState(inquiry.budget)

  const [object, setObject] = useState(inquiry.object)

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      // onClickAway={hide}
    >
      <div className="add-product-modal-container">
        <div className="left-container">
          <h3>Редакция на информация</h3>
          <h4 className="child-header">Етап: Запитване</h4>
          <span>Клиент</span>
          {/* {client ?
          <Input.Text
            value={client}
            placeholder="Име на фирмата"
            onChange={(e) => setClient(e.target.value)}
          /> : */}
              <ClientAutocomplete
              clientEdit={client}
              onChooseClient={(data) => {
                setClient(data.name)
              }}
              onChange={(event) => setClient(event.target.value)}
            />
          <span>Обект</span>
          <Input.Text
            value={object}
            placeholder="Обект"
            onChange={(e) => setObject(e.target.value)}
          />
          <div className="flex-container">
            <div className="budget-container">
              <span>Бюджет</span>
              <Input.Text
                value={budget}
                placeholder="Бюджет"
                onChange={(e) => {
                  if (isBudgetValid(e.target.value)) {
                    setBudget(e.target.value)
                  }
                }}
              />
            </div>
            <div className="date-container">
              <span>Начална дата</span>
              <Input.Datepicker
                placeholder="Дата"
                pickDate={startDate}
                onChange={(date) => setStartDate(moment(date).toDate())}
              />
            </div>
          </div>
          <Button.Raised
            onClick={() => {
              update({ _id: inquiry._id, client, budget, object, startDate })
            }}
            text="Запази"
          />
        </div>
      </div>
    </ClickAwayListener>
  )
}

const mapStateToProps = (state) => ({
  inquiry: state.inquiries.current,
  data: state.modal.data
})

const mapDispatchToProps = (dispatch) => ({
  update: (payload) => dispatch(updateInquiry(payload)),
  hide: () => dispatch(hideModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProductModal)
