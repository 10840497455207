import React, { useRef, useState, useEffect } from "react"
import { SingleRadioButton } from "../../components"
import { Section, Button } from "@makedonski/admin-ui"
import "./styles.scss"

const sortingMap = {
  "Вид продукт": "product",
  Доставчик: "provider",
  "Заявено на": "createdAt",
  "Доставка до": "deliveryDate",
  "Доставена на": "deliveredAt",
  "Заявено к-во": "quantity",
  Цена: "price",
  Плащане: "payments",
  Статус: "status",
}

function TableHeaderInquiry({ elements, justifyContent, width, handleSort, selectedSortValue, setSelectedSortValue, sort, setSort, tab}) {

  const [filter, setFilter] = useState("");

  return (
    <div className="table-header-container" style={{width: width}}>
      {elements.map((el) => {
        return (
              <div
                onClick={() => {
                  if (el?.filter) {
                    if (el.name !== selectedSortValue && selectedSortValue !== '') {
                      let prevSortValue = elements.find(el => el.sort === true)
                      if (prevSortValue) {
                        prevSortValue.sort = false
                      }
                    }
                    el.sort = !el.sort
                    if (tab && (tab === 'sales' || tab === 'production' || tab === 'expense')) {
                      setSelectedSortValue(el.name)
                      setSort(!sort)
                      handleSort(el.name)
                    }
                    else {
                      handleSort(el.name, el.sort)
                    }
                  }
                  // el?.filter && handleSort(!sort) && setSort(!sort);
                }}
                className="table-header-element"
                key={el?._id}
                style={{
                  flex: el.size,
                  width: el?.fixedWidth,
                  justifyContent: justifyContent || "center",
                  cursor: el?.filter ? "pointer" : "default",
                }}
              >
                <p>{el.name}</p>
                {el?.filter && 
                <div className="arrow-icon" style={ el.sort ? { transform: "rotate(180deg)" } : {} } />}
              </div>
        )
      })}
    </div>
  )
}

export default TableHeaderInquiry
