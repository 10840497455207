import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts, Auth } from '@makedonski/socourt-utilities'
import { UserTypes, GeneralTypes } from '../actions'
import { API_URL } from '../config/settings'

export const setPushTokenEpic = (action$) => {
  return action$.pipe(
    ofType(UserTypes.SET_PUSH_TOKEN),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/user`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            Auth.setUserKey('workingType', response.user.workingType).then(() => {
              obs.next({
                type: UserTypes.SET_PUSH_TOKEN_SUCCESS,
                payload: response.user,
              })
              obs.complete()
            })
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getcurrentUser = (action$) => {
  return action$.pipe(
    ofType(UserTypes.GET_CURRENT_USER_START),
    switchMap(() => {
      return ActionsObservable.create(
        (obs) => {
          obs.next({
            type: UserTypes.GET_CURRENT_USER_SUCCESS,
            payload: Auth.getUser(),
          })
        },
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({
              type: UserTypes.GET_CURRENT_USER_FAILURE,
              payload: err,
            })
            obs.complete()
          })
        })
      )
    })
  )
}

// export const getCurrentUserRole = (action$) => {
//   return action$.pipe(
//     ofType(UserTypes.GET_CURRENT_USER_ROLE),
//     switchMap(() => {
//       return ActionsObservable.create(
//         (obs) => {
//           obs.next({
//             type: UserTypes.GET_CURRENT_USER_ROLE_SUCCESS,
//             payload: Auth.getUser(),
//           })
//         },
//         catchError((err) => {
//           Alerts.error('Грешка', 'невалидни данни!')
//           return ActionsObservable.create((obs) => {
//             console.error(err)
//             obs.next({ type: GeneralTypes.ERROR, payload: err })
//             obs.complete()
//           })
//         })
//       )
//     })
//   )
// }

export const getCurrentUserRoleEpic = (action$) => {
  return action$.pipe(
    ofType(UserTypes.GET_CURRENT_USER_ROLE),
    switchMap(() => {
      return Api.get( `${API_URL}/users2/role`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: UserTypes.GET_CURRENT_USER_ROLE_SUCCESS,
              payload: response
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          // Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const changeWorkingStatusEpic = (action$) => {
  return action$.pipe(
    ofType(UserTypes.CHANGE_WORKING_STATUS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/user/change-working-type`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            Auth.setUserKey('workingType', response.user.workingType).then(() => {
              obs.next({
                type: UserTypes.CHANGE_WORKING_STATUS_SUCCESS,
                payload: response.user,
              })
              obs.complete()
            })
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
export const changeStatusEpic = (action$) => {
  return action$.pipe(
    ofType(UserTypes.CHANGE_STATUS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/user/change-status`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            Auth.setUserKey('status', response.user.status).then(() => {
              obs.next({
                type: UserTypes.CHANGE_STATUS_SUCCESS,
                payload: response.user,
              })
              obs.complete()
            })
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
export const getColleagues = (action$) => {
  return action$.pipe(
    ofType(UserTypes.GET_COLLEAGUES),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/user/filter`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: UserTypes.GET_COLLEAGUES_SUCCESS,
              payload: response.users,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getFilteredUsers = (action$) => {
  return action$.pipe(
    ofType(UserTypes.GET_FILTERED_USERS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/user/filter`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: UserTypes.GET_FILTERED_USERS_SUCCESS,
              payload: response.users,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getTasks = (action$) => {
  return action$.pipe(
    ofType(UserTypes.GET_USER_TASKS),
    switchMap(({ payload }) => {
      let url = ''
      if (payload) {
        url = `tasks/date/${payload.date}`
      } else {
        url = 'tasks/date'
      }
      return Api.get(`${API_URL}/${url}`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: UserTypes.GET_USER_TASKS_SUCCESS,
              payload: response,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error('Грешка', 'невалидни данни!')
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
