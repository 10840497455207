import { ofType, ActionsObservable } from "redux-observable"
import { switchMap, catchError } from "rxjs/operators"
import { Api, Alerts } from "@makedonski/socourt-utilities"
import {
  InquiriesTypes,
  GeneralTypes,
  ProjectTypes,
  getInquiriesSuccess,
  createInquirySuccess,
  getInquirySuccess,
  addProductsToInquirySuccess,
  addDocumentsToInquirySuccess,
  changeWorkingOnSuccess,
  addCommentSuccess,
  updateCommentSuccess,
  deleteCommentSuccess,
  addActionsSuccess,
  updateInquirySuccess,
  hideModal,
  hideLoading,
  deleteInquirySuccess,
  searchInquiryCategories,
} from "../actions"
import { API_URL } from "../config/settings"
// import { history } from '../config/stores'

export const getInquiriesEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_INQUIRIES),
    switchMap(() => {
      return Api.get(`${API_URL}/inquiries`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(getInquiriesSuccess(response))
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.next(hideLoading())
            obs.complete()
          })
        })
      )
    })
  )
}

export const removeInquiriesEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.DELETE_INQUIRY),
    switchMap(({ payload }) => {
      return Api.delete(`${API_URL}/inquiries`, { ...payload }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(deleteInquirySuccess(response.inquiry))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getInquiryEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_INQUIRY),
    switchMap(({ payload }) => {
      return Api.get(`${API_URL}/inquiries/${payload._id}`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(getInquirySuccess(response.inquiry))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createInquiriesEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.CREATE_INQUIRY),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/inquiries`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(createInquirySuccess(response.inquiry))
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchInquiries = (action$) => {
  return action$.pipe(
    ofType(ProjectTypes.SEARCH_INQUIRIES),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/inquiries/browse`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({ type: ProjectTypes.SEARCH_INQUIRIES_SUCCESS, payload: response })
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: ProjectTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addProductsToInquiryEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.ADD_PRODUCTS_TO_INQUIRY),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/inquiries/product`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(addProductsToInquirySuccess(response.inquiry))
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addDocumentsToInquiryEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.ADD_DOCUMENTS_TO_INQUIRY),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/inquiries/documents`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(addDocumentsToInquirySuccess(response.inquiry))
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const changeWorkingOnEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.CHANGE_WORKING_ON),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/inquiries/working-on`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(changeWorkingOnSuccess(response.inquiry))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addCommentEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.ADD_COMMENT),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/inquiries/comments`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(addCommentSuccess(response.inquiry))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const updateCommentEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.UPDATE_COMMENT),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/comments`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(updateCommentSuccess(response.comment))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const removeCommentEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.REMOVE_COMMENT),
    switchMap(({ payload }) => {
      return Api.delete(`${API_URL}/comments`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(deleteCommentSuccess(response.comment))
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addActionsEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.ADD_ACTIONS),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/inquiries/actions`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(addActionsSuccess(response.inquiry))
            obs.next(searchInquiryCategories({ search: "" }))
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const updateInquiryEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.UPDATE_INQUIRY),
    switchMap(({ payload }) => {
      return Api.put(`${API_URL}/inquiries`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next(updateInquirySuccess(response.inquiry))
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addInvoiceCredit = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.ADD_INVOICE_CREDIT),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/invoice/credit`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addInvoiceDebit = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.ADD_INVOICE_DEBIT),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/invoice/debit`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const changeInvoiceStatus = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.CHANGE_INVOICE_STATUS),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/invoice/status`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editInvoiceCredit = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.EDIT_INVOICE_CREDIT),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/invoice/credit`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editInvoiceDebit = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.EDIT_INVOICE_DEBIT),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/invoice/debit`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteInvoiceCredit = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.DELETE_INVOICE_CREDIT),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/offers/invoice/credit`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteInvoiceDebit = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.DELETE_INVOICE_DEBIT),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/offers/invoice/debit`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addProformInvoice = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.CREATE_PROFORM_INVOICE),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/proform-invoice`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addProformInvoiceEn = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.CREATE_PROFORM_INVOICE_EN),
    switchMap(({ payload }) => {
      return Api.post(
        `${API_URL}/offers/proform-invoice/en`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          if (err.response.message) {
            Alerts.error("Грешка", err.response.message)
          } else {
            Alerts.error("Грешка", "Невалидни данни!")
          }
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProformInvoice = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.EDIT_PROFORM_INVOICE),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/proform-invoice`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const editProformInvoiceEn = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.EDIT_PROFORM_INVOICE_EN),
    switchMap(({ payload }) => {
      return Api.put(
        `${API_URL}/offers/proform-invoice/en`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const deleteProformInvoice = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.DELETE_PROFORM_INVOICE),
    switchMap(({ payload }) => {
      return Api.delete(
        `${API_URL}/offers/proform-invoice`,
        JSON.stringify(payload)
      ).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.UPDATE_INQUIRY_SUCCESS,
              payload: response.inquiry,
            })
            obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const addZoneEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.CREATE_ZONE),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/zone`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.CREATE_ZONE_SUCCESS,
              payload: response.zone,
            })
            // obs.next(hideModal())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getZonesEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_ZONES),
    switchMap(() => {
      return Api.get(`${API_URL}/zone`).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: InquiriesTypes.GET_ZONES_SUCCESS,
              payload: response.zones,
            })
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getInquiryOffers = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_ALL_OFFERS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/offers/inquiry`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.GET_ALL_OFFERS_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getInquirySales = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_ALL_SALES),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/invoices/inquiry`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.GET_ALL_SALES_SUCCESS, payload: response})
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createInquiryChecklistEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.CREATE_CHECKLIST),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/checklist`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.CREATE_CHECKLIST_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const createInquiryStatusEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.CREATE_INQUIRY_STATUS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/inquiryStatus`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.CREATE_INQUIRY_STATUS_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getInquiryStatusesEpic = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_INQUIRY_STATUSES),
    switchMap(({ payload }) => {
      return Api.get(`${API_URL}/inquiryStatus`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.GET_INQUIRY_STATUSES_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const searchInquiryProduction = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.SEARCH_INQUIRIES_PRODUCTION),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/machines/schedule/stats2`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.SEARCH_INQUIRIES_PRODUCTION_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getInquiriesExpenseMachine = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_INQUIRY_EXPENSE_MACHINE),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/stats/machineCostPrice`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.GET_INQUIRY_EXPENSE_MACHINE_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getInquiriesExpenseEndProduct = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_INQUIRY_EXPENSE_END_PRODUCT),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/stats/productionCostPrice`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.GET_INQUIRY_EXPENSE_END_PRODUCT_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

export const getInquiriesDefectives = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.GET_INQUIRY_DEFECTIVES),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/stats/defectives`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.GET_INQUIRY_DEFECTIVES_SUCCESS, payload: response.defectives})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}

// export const searchInquiryClients = (action$) => {
//   return action$.pipe(
//     ofType(InquiriesTypes.SEARCH_CLIENTS),
//     switchMap(({ payload }) => {
//       return Api.post(`${API_URL}/client/list`, JSON.stringify(payload)).pipe(
//         switchMap(({ response }) => {
//           return ActionsObservable.create((obs) => {
//             obs.next({type: InquiriesTypes.SEARCH_CLIENTS_SUCCESS, payload: response})
//             obs.complete()
//           })
//         }),
//         catchError((err) => {
//           Alerts.error("Грешка", "невалидни данни!")
//           return ActionsObservable.create((obs) => {
//             console.error(err)
//             obs.next({ type: GeneralTypes.ERROR, payload: err })
//             obs.complete()
//           })
//         })
//       )
//     })
//   )
// }

// export const filterInquiryDatesClients = (action$) => {
//   return action$.pipe(
//     ofType(InquiriesTypes.FILTER_CLIENTS_DATES),
//     switchMap(({ payload }) => {
//       return Api.post(`${API_URL}/client/list`, JSON.stringify(payload)).pipe(
//         switchMap(({ response }) => {
//           return ActionsObservable.create((obs) => {
//             obs.next({type: InquiriesTypes.FILTER_CLIENTS_DATES_SUCCESS, payload: response})
//             obs.complete()
//           })
//         }),
//         catchError((err) => {
//           Alerts.error("Грешка", "невалидни данни!")
//           return ActionsObservable.create((obs) => {
//             console.error(err)
//             obs.next({ type: GeneralTypes.ERROR, payload: err })
//             obs.complete()
//           })
//         })
//       )
//     })
//   )
// }


export const searchInquiryOffers = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.SEARCH_INQUIRIES_OFFERS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/offers/inquiry`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.SEARCH_INQUIRIES_OFFERS_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}
export const filterInquiryDatesOffers = (action$) => {
  return action$.pipe(
    ofType(InquiriesTypes.FILTER_INQUIRIES_OFFERS),
    switchMap(({ payload }) => {
      return Api.post(`${API_URL}/offers/inquiry`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({type: InquiriesTypes.FILTER_INQUIRIES_OFFERS_SUCCESS, payload: response})
            obs.next(hideLoading())
            obs.complete()
          })
        }),
        catchError((err) => {
          Alerts.error("Грешка", "невалидни данни!")
          return ActionsObservable.create((obs) => {
            console.error(err)
            obs.next({ type: GeneralTypes.ERROR, payload: err })
            obs.complete()
          })
        })
      )
    })
  )
}


// export const searchInquirySales = (action$) => {
//   return action$.pipe(
//     ofType(InquiriesTypes.SEARCH_SALES),
//     switchMap(({ payload }) => {
//       return Api.post(`${API_URL}/invoices/inquiry`, JSON.stringify(payload)).pipe(
//         switchMap(({ response }) => {
//           return ActionsObservable.create((obs) => {
//             obs.next({type: InquiriesTypes.SEARCH_SALES_SUCCESS, payload: response})
//             obs.complete()
//           })
//         }),
//         catchError((err) => {
//           Alerts.error("Грешка", "невалидни данни!")
//           return ActionsObservable.create((obs) => {
//             console.error(err)
//             obs.next({ type: GeneralTypes.ERROR, payload: err })
//             obs.complete()
//           })
//         })
//       )
//     })
//   )
// }
