import React, { useRef } from "react"
import { connect } from "react-redux"
import { Button, Input } from "@makedonski/admin-ui"
import moment from "moment"
import CloseIcon from "@mui/icons-material/Close"
import * as animationData from "../../assets/animations/loading.json";
import {
  Button as ButtonMui,
  Paper,
  Divider,
  InputBase,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  TextField,
  Box,
  Stack,
} from "@mui/material"
import styles from "../Projects/Projects.module.scss"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Alerts } from '@makedonski/socourt-utilities/lib/Alerts'
import {
  showModal,
  deleteInquiry,
  searchInquiries,
  showLoading,
  hideLoading,
  getInquiryStatuses
} from "../../actions"
import "./styles.scss"
import _, { add } from "lodash"
import ProjectCard from "../ProjectCard/ProjectCard"
import { useState, useEffect } from "react"
import ProjectFilters from "../ProjectFilters/ProjectFilters"

const mapStatuses = {
  projection: "Проектиране",
  "choosing-provider": "Избор на доставчик",
  auction: "Търг за изпълнители",
  lost: "Загубен",
  finished: "Приключил",
  other: "Друго",
}

const mapStatusesReversed = {
  "Проектиране": "projection",
  "Избор на доставчик": "choosing-provider",
  "Търг за изпълнители": "auction",
  "Загубен": "lost",
  "Приключил": "finished",
  "Друго": "other",
}

const radioStyles = {
  "&.Mui-checked": {
    color: "#25E083",
  },
}

const disabledTextfieldStyles = {
  "& input.Mui-disabled": {
    "WebkitTextFillColor": "rgba(0, 0, 0, 1)",
  },
}

const headerElements = [
  {
    name: "Обект",
    class: 'medium',
    type: 'object',
    filter: true,
    sort: 1
  },
  {
    name: "Клиент",
    class: 'medium',
    type: 'client',
    filter: true,
    sort: 1
  },
  {
    name: "Бюджет",
    class: 'small',
    type: 'budget',
    filter: true,
    sort: 1
  },
  {
    name: "Отговорник",
    class: "small-medium",
    type: 'personResponsible'
  },
  {
    name: "Създаден на",
    class: 'small',
    type: 'createdAt',
    filter: true,
    sort: 1
  },
  {
    name: "Оферта",
    class: 'small',
    type: "offer"
  },
  {
    name: "Статус",
    class: 'small-medium',
    type: 'status',
    filter: true,
  },
  {
    name: "Зона",
    class: 'small',
    type: 'zone'
  },
  {
    name: "Проформа",
    class: 'small',
    type: 'proform'
  },
  {
    name: "Фактура",
    class: 'small',
    type: 'invoice'
  },
  {
    name: "ППП",
    class: 'small',
    type: 'ppp'
  },
  {
    name: "Последна активност",
    class: 'small',
    type: 'updatedAt',
    sort: 1
  }
]

const ProjectsView = ({ inquiries, statuses, activeThisMonth, activeThisWeek, searchInquiries, inquiriesPage, showLoading, totalDocs, showModal, getInquiryStatuses }) => {

  const [inquiriesSet, setInquiriesSet] = useState(inquiries)
  const [sortStatuses, setSortStatuses] = useState([])
  const [chosenSortStatuses, setChosenSortStatuses] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isDateChosen, setIsDateChosen] = useState(false)
  const [selectedSortValue, setSelectedSortValue] = useState('')
  const [sortType, setSortType] = useState(-1)
  const [periodSearchAnchor, setPeriodSearchAnchor] = useState(null)
  const periodSearchOpen = Boolean(periodSearchAnchor)
  const handlePeriodSearchOpen = (event) => {
    setPeriodSearchAnchor(event.currentTarget)
  }
  const handlePeriodSearchClose = () => {
    setPeriodSearchAnchor(null)
  }
  const [filteredInquiries, setFilteredInquiries] = useState([])
  const [initialInquiriesState, setInitialInquiriesState] = useState([])
  const [query, setQuery] = useState("")
  const [searchQuery, setSearchQuery] = useState("Име на обект")
  const [radioBtnValue, setRadioBtnValue] = useState("object")
  const [radioBtnPeriod, setRadioBtnPeriod] = useState("createdAt")
  const [periodSearchQuery, setPeriodSearchQuery] = useState("Дата на създаване")
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  let currentPayload = {}
  const [ payload, setPayload ] = useState({})

  const scrollRef = useRef(null)
  const resetFilterIcon = require('../../assets/icons/remove-filter-icon.svg')
  const [ isLoading, setIsLoading ] = useState(true)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRadioBtnPeriodChange = (event) => {
    switch (event.target.value) {
      case "createdAt":
        setPeriodSearchQuery("Дата на създаване")
        break
      case "updatedAt":
        setPeriodSearchQuery("Последна активност")
        break
      default:
        setPeriodSearchQuery("Дата на създаване")
        break
    }
    setRadioBtnPeriod(event.target.value)
    // setIsDateChosen(true)
  }

  const handleRadioBtnChange = (event) => {
    switch (event.target.value) {
      case "object":
        setSearchQuery("Име на обект")
        break
      case "client":
        setSearchQuery("Име на клиент")
        break
      case "refNumber":
        setSearchQuery("Номер на оферта")
        break
      case "assignee":
        setSearchQuery("Име на отговорник")
        break
      // default:
      //   setSearchQuery("Име на обект")
      //   break
    }
    setRadioBtnValue(event.target.value)
  }

  const handleStatusChange = (event) => {
    const { name, checked } = event.target
    // let newSortStatuses = [...chosenSortStatuses]
    // const updatedStatusesState = newSortStatuses.map((status) =>
    //   status === name ? { ...status, checked: checked } : status
    // )

    const newChosen = chosenSortStatuses.slice(0);
    const index = newChosen.findIndex(el => el === name)
    if (index === -1) {
      newChosen.push(name)

      if (mapStatusesReversed[name]) {
        newChosen.push(mapStatusesReversed[name])
      }
    } else {
      newChosen.splice(index, 1)  

      if (mapStatusesReversed[name]) {
        let secondIndex = newChosen.indexOf(mapStatusesReversed[name])
        newChosen.splice(secondIndex, 1)
      }
    }

    setChosenSortStatuses(newChosen)
  }

  const handleScrolling = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight } = scrollRef.current
      const innerHeight = scrollRef.current.getBoundingClientRect().height
      if (scrollTop + innerHeight >= scrollHeight - 500) {
        setIsLoading(true)
        onReachEnd(radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen)
      }
    }
  }

  const delayedScrolling = _.debounce(() => {
    handleScrolling() 
  }, 300)

  const filterPayload = (radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen, sortType, selectedSortValue) => {
    if (query) {
      if (radioBtnValue !== 'assignee') {
        currentPayload[radioBtnValue] = query
      }
      else if (radioBtnValue === 'assignee') {
        currentPayload.personResponsible = query
      }
    }

    if (isDateChosen) {
      if (periodSearchQuery === 'Дата на създаване') {
        if (moment(startDate).startOf("day").isSame(moment(endDate).startOf("day")) || !endDate) {
          currentPayload.createdAt = moment(startDate).clone().add(1, 'day')
        }
        else {
          currentPayload.createdAtFrom = startDate
          currentPayload.createdAtTo = moment(endDate).clone().add(1, 'day')
        }
      }
      else if (periodSearchQuery === 'Последна активност') {
        if (moment(startDate).startOf("day").isSame(moment(endDate).startOf("day")) || !endDate) {
          currentPayload.actionDate = moment(startDate).clone().add(1, 'day')
        }
        else {
          currentPayload.actionDateFrom = startDate
          currentPayload.actionDateTo = endDate
        } 
      }
    }

    if (selectedSortValue) {
      currentPayload.sortBy = {[selectedSortValue]: sortType}
    }

    if (chosenSortStatuses?.length) {
      currentPayload.statuses = chosenSortStatuses

      if (inquiriesPage - 1 > 1) {
        currentPayload.skipReturningStatuses = ''
      }
    }
  }

  const onReachEnd = (radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen) => {
    if (inquiriesPage) {
      currentPayload = JSON.parse(JSON.stringify(payload))
      filterPayload(radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen)
      currentPayload.pageSize = 30
      currentPayload.pageNumber = inquiriesPage
      showLoading()
      searchInquiries(currentPayload)
    }
  }

  const handleFilterStatuses = (radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen) => {
    if (isDateChosen && 
      moment(startDate).startOf("day").isAfter(moment(endDate).startOf("day"))) {
      return Alerts.error("Зададената начална дата на търсене е след крайната дата!")
    }

    if (!startDate && endDate) {
      return Alerts.error("Няма зададена начална дата за търсене!")
    }

    filterPayload(radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen)
    currentPayload.pageNumber = 1
    currentPayload.pageSize = 30

    showLoading()
    scrollRef.current && scrollRef.current.scrollTo(0, 0)
    setInquiriesSet([])
    searchInquiries(currentPayload)
  }

  const resetFilters = () => {
    showLoading()
    setFilteredInquiries(initialInquiriesState)
    setQuery("")
    setSearchQuery("Име на обект")
    setRadioBtnValue("object")
    setStartDate(null)
    setEndDate(null)
    setPayload({})
    currentPayload = {}
    setIsDateChosen(false)
    setPeriodSearchQuery("Дата на създаване")
    setSortType(-1)
    setSelectedSortValue('')
    setChosenSortStatuses([])
    searchInquiries({pageSize: 30, pageNumber: 1})
  }

  useEffect(() => {
    setInquiriesSet(inquiries)
    setIsLoading(false)
  }, [inquiries])

  useEffect(() => {
    showLoading()
    searchInquiries({pageSize: 30, pageNumber: inquiriesPage})
    getInquiryStatuses()
  }, [])

  useEffect(() => {
   if (statuses?.length) setSortStatuses(statuses)
  }, [statuses])

  return (
    <div className="projects-view-container">
      <div className="top-projects-view-items">
        <Stack 
        direction="row"
        spacing={2}
        alignItems="center"
        className={styles["search-wrapper"]}
        >
          {/* <div className={styles["query-search-wrapper"]}> */}
          <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          className={styles["query-search-wrapper"]}>
          <Paper
              component={"form"}
              sx={{
                // p: "2px 4px",
                display: "flex",
                alignItems: "center",
                minWidth: "400px",
                justifyContent: "space-between",
              }}
            >
              <InputBase
                inputProps={{
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation()
                      e.preventDefault()
                    }
                  },
                }}
                fullWidth={true}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Търси по"
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <ButtonMui
                style={{ textTransform: "none", color: "black", marginRight: "10px" }}
                disableElevation
                disableRipple
                disableFocusRipple
                className={styles["filter-button"]}
                endIcon={<KeyboardArrowDownIcon style={{ color: "#26ACE2" }} />}
                onClick={handleOpen}
              >
                {searchQuery}
              </ButtonMui>
              <Menu
                PaperProps={{ style: { padding: "15px" } }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div className={styles["filters-options"]}>
                  <h5>Търсене по</h5>
                  <CloseIcon
                    fontSize="small"
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <FormControl>
                  <RadioGroup
                    value={radioBtnValue}
                    onChange={handleRadioBtnChange}
                  >
                    <FormControlLabel
                      value="object"
                      control={<Radio sx={radioStyles} />}
                      label="Име на обект"
                    />
                    <FormControlLabel
                      value="client"
                      control={<Radio sx={radioStyles} />}
                      label="Име на клиент"
                    />
                    <FormControlLabel
                      value="refNumber"
                      control={<Radio sx={radioStyles} />}
                      label="Номер на оферта"
                    />
                    <FormControlLabel
                      value="assignee"
                      control={<Radio sx={radioStyles} />}
                      label="Име на отговорник"
                    />
                  </RadioGroup>
                  <ButtonMui
                    sx={{
                      background: "#26ACE2",
                      "&:hover": {
                        background: "#26ACE2",
                      },
                    }}
                    onClick={handleClose}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    variant="contained"
                    fullWidth
                  >
                    Задай
                  </ButtonMui>
                </FormControl>
                
              </Menu>
              
            </Paper>
            <ButtonMui
              onClick={() => {
                handleFilterStatuses(radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen)
                setIsLoading(true)
              }}
              // onClick={handleFilter}
              variant="contained"
              sx={{
                padding: "6px 25px",
                backgroundColor: "#E6EEF4",
                "&:hover": {
                  background: "#E6EEF4",
                },
                color: "black",
                marginLeft: "10px",
                textTransform: "none"
              }}
            >
              Търси
            </ButtonMui>
          </Stack>
          {/* </div> */}
          
          <Stack 
            direction="row"
            spacing={2}
            alignItems="center"
            className={styles["date-search-wrapper"]}
          >
            <Input.Datepicker
              className={styles.datepicker}
              placeholder="Дата"
              minDate={moment()}
              pickDate={startDate}
              onChange={(date) => {
                setIsDateChosen(true)
                setStartDate(date)
              }}
            />
            <Box sx={{ m: 1 }}>-</Box>
            <Input.Datepicker
              className={styles.datepicker}
              placeholder="Дата"
              minDate={moment()}
              pickDate={endDate || startDate}
              onChange={(date) => {
                setIsDateChosen(true)
                setEndDate(date)
              }}
            />
            <TextField
              value={periodSearchQuery}
              size="small"
              disabled
              style={{
                width: "220px",
                margin: "0px 10px",
              }}
              sx={disabledTextfieldStyles}
              InputProps={{
                style: { fontSize: "14px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyboardArrowDownIcon
                      style={{ cursor: "pointer" }}
                      onClick={handlePeriodSearchOpen}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Menu
              PaperProps={{ style: { padding: "15px" } }}
              anchorEl={periodSearchAnchor}
              open={periodSearchOpen}
              onClose={handlePeriodSearchClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div className={styles["filters-options"]}>
                <h5>Търси по</h5>
                <CloseIcon
                  fontSize="small"
                  onClick={handlePeriodSearchClose}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <FormControl>
                <RadioGroup
                  value={radioBtnPeriod}
                  onChange={handleRadioBtnPeriodChange}
                >
                  <FormControlLabel
                    value="createdAt"
                    control={<Radio sx={radioStyles} />}
                    label="Дата на създаване"
                  />
                  <FormControlLabel
                    value="updatedAt"
                    control={<Radio sx={radioStyles} />}
                    label="Последна активност"
                  />
                </RadioGroup>
              </FormControl>
            </Menu>
            <ButtonMui
              onClick={() => {
                handleFilterStatuses(radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen)
                setIsLoading(true)
              }}
              variant="contained"
              sx={{
                padding: "6px 25px",
                backgroundColor: "#E6EEF4",
                "&:hover": {
                  background: "#E6EEF4",
                },
                color: "black",
                textTransform: "none"
              }}
            >
              Търси
            </ButtonMui>
          </Stack>
          <div>
          <ButtonMui
            onClick={() => {
              // setSortParams("")
              scrollRef.current && scrollRef.current.scrollTo(0, 0)
              resetFilters()
            }}
            className='remove-filter-btn'>
            <img src={resetFilterIcon} alt='reset filters' />
          </ButtonMui>
        </div>

      </Stack>
        <div className="right-side">
          <Button.Raised
            text="Добави запитване"
            onClick={() =>
              showModal({
                type: "addProductQueryModal",
                width: "1100px",
                overflow: "visible",
              })
            }
          />
        </div>
      </div>
      <div className={`main-projects-view-container${(!inquiriesSet.length && isLoading === false) ? ' flex' : ''}`}>
        {(!inquiriesSet.length && !isLoading) ? 
        <div className="no-projects-msg">
          {(!query && !isDateChosen) ? 
          'Все още няма създадени добавени проекти. Започнете като натиснете бутона “Добави запитване”' :
          'Няма резултати'}
        </div> : <> 
        <ProjectFilters
          // sortStatuses={[]}
          sortStatuses={sortStatuses}
          setSortStatuses={setSortStatuses}
          chosenSortStatuses={chosenSortStatuses}
          headerElements={headerElements}
          handleFilterStatuses={() => handleFilterStatuses()}
          selectedSortValue={selectedSortValue}
          sort={sortType}
          handleStatusChange={handleStatusChange}
          setFilters={(sort, value) => {
            if (selectedSortValue === value || selectedSortValue === '') {
              sort === 1 ? setSortType(-1) : setSortType(1)
              sort = sort === 1 ? -1 : 1
            }

            setSelectedSortValue(value)
            filterPayload(radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen, sort, value)
            handleFilterStatuses(radioBtnValue, query, startDate, endDate, periodSearchQuery, isDateChosen)
            scrollRef.current && scrollRef.current.scrollTo(0, 0)
            setPayload(currentPayload)
          }}
          // setSort={(type) => setSortType(type)}
          // setValue={(value) => setSelectedSortValue(value)}
        />
        <div
          className="scroll-container"
          ref={scrollRef}
          onScroll={delayedScrolling}
        >
          {inquiriesSet
            ?.map((inquiry) => {
              return (
                <div
                  key={inquiry?._id}
                  onClick={() => {
                    window.open(`/projects/${inquiry._id}`)
                  }}
                >
                  <ProjectCard
                    additionalStatuses={inquiry?.additionalStatuses}
                    key={inquiry?._id}
                    budget={inquiry?.budget}
                    client={inquiry?.client}
                    createdAt={inquiry?.createdAt}
                    invoices={inquiry?.invoices}
                    object={inquiry?.object}
                    offers={inquiry?.offers}
                    lastActivity={inquiry?.lastActivity}
                    proform_invoices={inquiry?.proform_invoices}
                    status={mapStatuses[inquiry?.status] || inquiry?.status}
                    searchQuery={searchQuery}
                    query={query}
                    refNumbers={inquiry?.refNumbers}
                    assignee={inquiry?.working_on?.[0]?.user || "-"}
                    actsofacceptance={inquiry?.actsofacceptance}
                    zones={inquiry?.zones}
                    assignees={inquiry?.working_on}
                  />
                </div>
              )
            })}
        </div></>}
      </div>
      <div className="bottom-label-container">
        <div className="text-container">
          <p>Общ брой проекти: <span>{totalDocs}</span></p>
        </div>
        <div className="text-container">
          <p>Активни тази седмица: <span>{activeThisWeek}</span></p>
        </div>
        <div className="text-container">
          <p>Активни тeкущ месец: <span>{activeThisMonth}</span></p>
        </div>
      </div>
      {/* {isLoading && <Lottie config={defaultOptions} />} */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiries: state.inquiries.inquiries,
  inquiriesPage: state.inquiries.inquiriesPage,
  totalDocs: state.inquiries.totalDocs,
  activeThisMonth: state.inquiries.activeThisMonth,
  activeThisWeek: state.inquiries.activeThisWeek,
  statuses: state.inquiries.statuses
})

const mapDispatchToProps = (dispatch) => ({
  showModal: (payload) => dispatch(showModal(payload)),
  removeInquiry: (payload) => dispatch(deleteInquiry(payload)),
  showLoading: () => dispatch(showLoading()),
  hideLoading: () => dispatch(hideLoading()),
  searchInquiries: (payload) => dispatch(searchInquiries(payload)),
  getInquiryStatuses: () => dispatch(getInquiryStatuses())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsView)
