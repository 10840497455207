import React from "react"
import { connect } from "react-redux"
import { getInquiries, searchInquiries, showLoading } from "../../actions"
import { ProjectsView } from "../../components/"
import { Lottie } from "@crello/react-lottie";
import { useState, useEffect } from "react";
import * as animationData from "../../assets/animations/loading.json";
import "./styles.scss"

const Projects = ({ inquiries }) => {

  const [ isLoading, setIsLoading ] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
  };

  // useEffect(() => {
  //   showLoading()
  //   searchInquiries({pageSize: 30, pageNumber: inquiriesPage})
  // }, [])

  return (
    <div className="main-projects-container">
      <ProjectsView />
    </div>
  )
}

const mapStateToProps = (state) => ({
  inquiries: state.inquiries.inquiries,
  inquiriesPage: state.inquiries.inquiriesPage,
  totalDocs: state.inquiries.totalDocs,
  activeThisMonth: state.inquiries.activeThisMonth,
  activeThisWeek: state.inquiries.activeThisWeek,
})

const mapDispatchToProps = (dispatch) => ({
  getList: (payload) => dispatch(getInquiries(payload)),
  showLoading: () => dispatch(showLoading()),
  // searchInquiries: (payload) => dispatch(searchInquiries(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
