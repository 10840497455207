import React from 'react';
import { useState } from 'react';
import { InquiriesClientsComponent } from '../../components';
import { InquiriesCounterpartiesComponent } from '../../components';
import InquiriesOffersComponent from '../../components/InquiriesOffersComponent/InquiriesOffersComponent';
import InquiriesSalesComponent from '../../components/InquiriesSalesComponent/InquiriesSalesComponent';
import InquiriesProductionComponent from '../../components/InquiriesProductionComponent/InquiriesProductionComponent';
import { InquiriesExpenseComponent, InquiriesDefectivesComponent } from '../../components';
import './styles.scss';

export default function Inquiries() {

    const tabs = [
        { name: "Клиенти",component: <InquiriesClientsComponent /> },
        { name: "Контрагенти", component: <InquiriesCounterpartiesComponent /> },
        {name: "Оферти", component: <InquiriesOffersComponent /> },
        {name: "Продажби", component: <InquiriesSalesComponent /> },
        {name: "Производство", component: <InquiriesProductionComponent /> },
        {name: "Себестойност", component: <InquiriesExpenseComponent /> },
        {name: "Брак", component: <InquiriesDefectivesComponent /> }
      ];
      const [selectedTab, setSelectedTab] = useState(tabs[0]);

      return (
        <div className="inquiries-container">
          <div className="top-bar-container">
            {tabs.map((tab, i) => (
              <div
                key={`storage-tab-${i}`}
                className={`option ${tab.name === selectedTab.name && "active"}`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab.name}
              </div>
            ))}
          </div>
          {selectedTab.component}
        </div>
      )
}
