import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Input } from '@makedonski/admin-ui'
import { history } from '../../config/stores'
import CloseIcon from "@mui/icons-material/Close"
import {
  getClients,
  getSuppliers,
  getInquiryOffers,
  searchInquiryProduction,
  getInquiryExpenseMachine,
  getInquiryDefectives,
  showLoading
} from '../../actions'
import styles from "../Projects/Projects.module.scss"
import './InquiriesHeader.scss'
import {Button as ButtonMUI, Menu, FormControl, RadioGroup, FormControlLabel, Radio} from '@mui/material'
import moment from 'moment'

export default function InquiriesHeader ({
  headerElements, handleHeaderElements, handleSort, startDate, endDate, tab, handleSearch, filterOptions, searchQuery,
  setSearchQuery, searchValue, setSearchValue, dateFilterOptions, setStartDate, setEndDate, setFiltersPayload, inquiriesRef,
  searchDateValue, setSearchDateValue, handleDatesFilter, setAreDatesSelected, productionData, isFilterActive, filtersPayload,
  sortedSales, setSortedSales, salesData, selectedSortValue, setSelectedSortValue, sort, setSort, setIsFilterActive, handleFilters }) {

  const radioStyles = {
    "&.Mui-checked": {
      color: "#25E083",
    },
  }

  const removeFilterIcon = require('../../assets/icons/remove-filter-icon.svg')
  const blueArrowDownIcon = require('../../assets/icons/blue-arrow-dropdown.svg')
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [dateAnchorEl, setDateAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const openDate = Boolean(dateAnchorEl)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)

    if (tab === 'defectives') {
      handleFilters()
    }
  }
  const handleOpenDate = (event) => {
    setDateAnchorEl(event.currentTarget)
  }
  const handleCloseDate = () => {
    setDateAnchorEl(null)
  }

  const resetFilters = () => {
    dispatch(showLoading())
    setStartDate(moment().toDate())
    setEndDate(moment().toDate())
    if (searchQuery) setSearchQuery('')
    setAreDatesSelected(false)
    searchDateValue && setSearchDateValue("createdAt")
    selectedSortValue && setSelectedSortValue('')
    sort && setSort(true)
    if (tab === 'clients') {
      setSearchValue('name')
      dispatch(getClients({ pageNumber: 1, pageSize: 20 }))
    }
    else if (tab === 'counterparties') {
      setSearchValue('firmName')
      dispatch(getSuppliers({ pageNumber: 1, pageSize: 20 }))
    }
    else if (tab === 'offers') {
      setSearchValue('client')
      dispatch(getInquiryOffers({ pageNumber: 1, pageSize: 20 }))
    }
    else if (tab === 'sales') {
      sortedSales && setSortedSales(salesData)
    }
    else if (tab === 'production') {
      // dispatch(searchInquiryProduction({ name: ''}))
      dispatch(searchInquiryProduction({ name: '', from: moment().subtract(7, 'days'), to: moment() }));
      setSelectedSortValue('Дата')
      setStartDate(moment().subtract(7, 'days').toDate())
      setEndDate(moment().toDate())
      setAreDatesSelected(true)
      setSort(false)
      handleHeaderElements('Дата')
      // handleSort('Дата')
    }
    else if (tab === 'expense') {
      dispatch(getInquiryExpenseMachine({ start: moment().subtract(1, 'month'), end: moment() })) //default should be 1 month
      setSearchValue('machines')
      setStartDate(moment().subtract(1, 'month').toDate())
      setEndDate(moment().toDate())
      setSort(true)
      setFiltersPayload({ machines: [], performers: [] })
    }
    else if (tab === 'defectives') {
      dispatch(getInquiryDefectives({ start: moment().subtract(1, 'month'), end: moment() }))
      setSelectedSortValue('Дата')
      setSearchValue('machines')
      setStartDate(moment().subtract(1, 'month').toDate())
      setEndDate(moment().toDate())
      setSort(false)
      inquiriesRef.current && inquiriesRef.current.scrollTo({top: 0, behavior: 'smooth'})
    }
    headerElements && headerElements.map(el => el.sort = false)
  }

  return (
    <div className='flex-container'>
      <form className='input-form-container' onSubmit={handleSearch}>
        <div className='search-col'>
          {(tab !== 'expense' && tab !== 'defectives') &&
          <div className='search-input-wrapper'>
            <label htmlFor='client-name'>Търсене</label>
            <Input.Text
              placeholder='Търси' id='client-name' value={searchQuery} onChange={(e) => {
                setSearchQuery(e.target.value)
                if (searchQuery === '' && tab === 'sales') {
                  sortedSales && setSortedSales(salesData)
                }
              }}
            />
          </div>}

          {(tab !== 'expense' && tab !== 'defectives') ?
          <div className='search-value-img-wrapper production' onClick={handleOpen}>
            <Input.Text value={filterOptions[searchValue]}/>
            {tab !== 'production' && <img src={blueArrowDownIcon} />}
          </div> : 
          <div className='search-value-img-wrapper expense' onClick={handleOpen}>
            <label htmlFor='filter-name'>Преглед на</label>

            <div className='flex-container'>
              <Input.Text value={filterOptions[searchValue]}></Input.Text>
              <img src={blueArrowDownIcon} />
            </div>
          </div>}

              {tab !== 'production' &&
              <Menu
                PaperProps={{ style: { padding: "15px" } }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <div className={styles["filters-options"]}>
                  {tab !== 'defectives' ? <h5>Търсене по</h5> : <h5>Преглед на</h5>}
                  <CloseIcon
                    fontSize="small"
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <FormControl>
                  <RadioGroup
                    value={searchValue}
                    // value={filterOptions[searchValue]}
                    onChange={е => setSearchValue(е.target.value)}
                  >
                    {(tab === 'clients' || tab === 'counterparties') &&
                    <>
                    <FormControlLabel
                      value={tab === 'clients' ? 'name' : 'firmName'}
                      control={<Radio sx={radioStyles} />}
                      label="Име на клиент"
                    />
                    <FormControlLabel
                      value="phone"
                      control={<Radio sx={radioStyles} />}
                      label="Телефон"
                    />
                    <FormControlLabel
                      value="email"
                      control={<Radio sx={radioStyles} />}
                      label="Имейл"
                    />
                    <FormControlLabel
                      value="address"
                      control={<Radio sx={radioStyles} />}
                      label="Адрес"
                    />
                    <FormControlLabel
                      value="uic"
                      control={<Radio sx={radioStyles} />}
                      label="ЕИК"
                    />
                    </>
                    }
                    {tab === 'offers' && 
                    <>
                    <FormControlLabel
                      value="client"
                      control={<Radio sx={radioStyles} />}
                      label="Име на клиент"
                    />
                    <FormControlLabel
                      value="object"
                      control={<Radio sx={radioStyles} />}
                      label="Обект"
                    />
                    </>}
                    {tab === 'sales' && 
                    <>
                      <FormControlLabel
                      value="all"
                      control={<Radio sx={radioStyles} />}
                      label="Всички"
                    />
                    <FormControlLabel
                      value="invoiceNumber"
                      control={<Radio sx={radioStyles} />}
                      label="№ на документ"
                    />
                    <FormControlLabel
                      value="product"
                      control={<Radio sx={radioStyles} />}
                      label="Стока/артикул"
                    />
                    <FormControlLabel
                      value="client"
                      control={<Radio sx={radioStyles} />}
                      label="Партньор"
                    />
                    <FormControlLabel
                      value="createdBy"
                      control={<Radio sx={radioStyles} />}
                      label="Потребител"
                    />
                    </>}
                    {tab === 'expense' && 
                    <>
                      <FormControlLabel
                      value="machines"
                      control={<Radio sx={radioStyles} />}
                      label="Машини"
                    />
                    <FormControlLabel
                      value="endProduct"
                      control={<Radio sx={radioStyles} />}
                      label="Краен продукт"
                    />
                    </>}
                    {tab === 'defectives' && 
                    <>
                      <FormControlLabel
                      value="machines"
                      control={<Radio sx={radioStyles} />}
                      label="Всички машини"
                    />
                    <FormControlLabel
                      value="OD800"
                      control={<Radio sx={radioStyles} />}
                      label="OD800"
                    />
                    <FormControlLabel
                      value="OD2000"
                      control={<Radio sx={radioStyles} />}
                      label="OD2000"
                    />
                    <FormControlLabel
                      value="OD2400"
                      control={<Radio sx={radioStyles} />}
                      label="OD2400"
                    />
                    </>}
                  </RadioGroup>
                  <ButtonMUI
                    sx={{
                      background: "#26ACE2",
                      "&:hover": {
                        background: "#26ACE2",
                      },
                    }}
                    onClick={handleClose}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                    variant="contained"
                    fullWidth
                  >
                    Задай
                  </ButtonMUI>
                </FormControl>
                
              </Menu>}

          {(tab !== 'expense' && tab !== 'defectives') ?
          <div className='btn-wrapper'>
            <ButtonMUI
              variant='contained'
              sx={{
                padding: '6px 25px',
                backgroundColor: '#E6EEF4',
                '&:hover': {
                  background: '#E6EEF4'
                },
                color: 'black',
                textTransform: 'none'
              }}

              onClick={handleSearch}
            >
              Търси
            </ButtonMUI>
          </div> : 
          (tab !== 'defectives' ? 
          <div className='btn-wrapper expense'>
            {(filtersPayload?.machines?.length > 0 || filtersPayload?.performers?.length > 0) ?
              <img src={require("../../assets/icons/filter-blue-active.svg")} onClick={() => setIsFilterActive(true)} /> :
              <img src={require("../../assets/icons/filter-blue.svg")} onClick={() => setIsFilterActive(true)} />}
          </div> : <></>)}
        </div>

        <div className='filter-col'>
          <div className='filter-dates'>
            <label htmlFor='filter-name'>Филтрация по период</label>
            <div className='dates-wrapper'>
              <Input.Datepicker
                minDate={moment().subtract(50, 'months').toDate()}
                pickDate={startDate}
                onChange={(date) => {
                  setAreDatesSelected(true)
                  setStartDate(date)
                }}
              />
              <p className='dash'>-</p>
              <Input.Datepicker
                minDate={moment().subtract(50, 'months').toDate()}
                pickDate={endDate}
                onChange={(date) => {
                  setAreDatesSelected(true)
                  setEndDate(date)
                }}
              />
            </div>
          </div>

          <div className='search-dates'>
            {tab === 'offers' &&
              <label htmlFor='filter-search'>Търси избрания период по</label>}

            <div className='input-btn-wrapper'>
              {tab === 'offers' &&
                <>
                  <div className='search-value-img-wrapper' onClick={handleOpenDate}>
                  {/* <Input.Dropdown
                    placeholder={(tab === 'sales' && '№ на документ') || 'Име на клиент'}
                    // options={filterOptions}
                     value={filterOptions[searchValue]}
                  /> */}
                  <Input.Text value={dateFilterOptions[searchDateValue]}/>
                  <img src={blueArrowDownIcon} className='arrow-icon' />
                  </div>
        
                      <Menu
                        PaperProps={{ style: { padding: "15px" } }}
                        anchorEl={dateAnchorEl}
                        open={openDate}
                        onClose={handleCloseDate}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      >
                        <div className={styles["filters-options"]}>
                          <h5>Търсене по</h5>
                          <CloseIcon
                            fontSize="small"
                            onClick={handleCloseDate}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <FormControl>
                          <RadioGroup
                            value={searchDateValue}
                            onChange={е => setSearchDateValue(е.target.value)}
                          >
                            <FormControlLabel
                              value="createdAt"
                              control={<Radio sx={radioStyles} />}
                              label="Дата на създаване"
                            />
                            <FormControlLabel
                              value="updatedAt"
                              control={<Radio sx={radioStyles} />}
                              label="Последна редакция"
                            />
                     </RadioGroup>
                     <ButtonMUI
                       sx={{
                         background: "#26ACE2",
                         "&:hover": {
                           background: "#26ACE2",
                         },
                       }}
                       onClick={handleCloseDate}
                       disableElevation
                       disableRipple
                       disableFocusRipple
                       variant="contained"
                       fullWidth
                     >
                       Задай
                     </ButtonMUI>
                   </FormControl>
                
                   </Menu>
                </>
                }

              <div className='btn-wrapper'>
                <ButtonMUI
                  variant='contained'
                  sx={{
                      padding: '6px 25px',
                      backgroundColor: '#E6EEF4',
                      '&:hover': {
                        background: '#E6EEF4'
                      },
                      color: 'black',
                      textTransform: 'none'
                    }}
                  onClick={handleDatesFilter}
                >
                  Търси
                </ButtonMUI>

                <ButtonMUI onClick={resetFilters} className='remove-filter-btn'>
                  <img src={removeFilterIcon} alt='remove filter' />
                </ButtonMUI>
              </div>
            </div>
          </div>

        </div>
      </form>

      {
        (tab === 'clients' || tab === 'counterparties') &&
          <div className='btn-wrapper'>
            <Button.Raised
        // className={tab === 'counterparties' ? 'add-counterparty-btn' : ''}
              text={(tab === 'clients' && 'Добави клиент') ||
                (tab === 'counterparties' && 'Добави контрагент')}
              onClick={() => {
                history.push('/inquiries/add', { data: tab === 'clients' ? 'client' : 'supplier'})
              }}
            />
          </div>
      }

    </div>
  )
}
